




















import Component, { mixins } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";
import ValidatableMixin from "@/mixins/ValidatableMixin.vue";

@Component
export default class TCheckbox extends mixins(ValidatableMixin) {
  @Model("checked", { default: true })
  private readonly checked!: boolean;

  @Prop({ default: () => [] })
  private readonly rules!: ((input: boolean) => string | null)[];

  @Prop({ default: false, type: Boolean })
  private readonly disabled!: boolean;

  @Prop({ default: null })
  private readonly errorMessage!: string | null;

  @Watch("checked")
  private updateValidator() {
    this.validator.validate();
  }

  validate(): boolean {
    return this.validationResult === null;
  }

  private get validationResult(): string | null {
    for (const rule of this.rules) {
      const result = rule(this.checked);
      if (result) {
        return result;
      }
    }

    return null;
  }

  private onClick(e: MouseEvent) {
    const target = e.target as HTMLInputElement;

    this.$emit("checked", target.checked);
  }
}
