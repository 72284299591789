






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

type ColumnType =
  | "auto"
  | "wrap"
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | null;

@Component
export default class TCol extends Vue {
  @Prop({ default: null })
  private readonly cols!: ColumnType;

  @Prop({ default: null })
  private readonly sm!: ColumnType;

  @Prop({ default: null })
  private readonly md!: ColumnType;

  @Prop({ default: false, type: Boolean })
  private readonly noGutter!: boolean;

  private get currentBreakpointName() {
    return this.$breakpoints.currentBreakpoint.name;
  }

  private get computedClass() {
    const classes: { [key: string]: boolean } = {
      "no-gutter": this.noGutter
    };
    classes[this.colProperty] = true;
    return classes;
  }

  private get colProperty() {
    let colsValue: ColumnType;
    if (this.currentBreakpointName === "xs") {
      colsValue = this.cols;
    } else if (this.currentBreakpointName === "sm" && this.sm) {
      colsValue = this.sm;
    } else if (this.md) {
      colsValue = this.md;
    } else if (this.sm) {
      colsValue = this.sm;
    } else {
      colsValue = this.cols;
    }

    if (colsValue === null) {
      return "";
    }

    return "t-col-" + colsValue;
  }
}
