














import Vue from "vue";
import Component from "vue-class-component";
import TRow from "@/components/TRow.vue";
import TCol from "@/components/TCol.vue";
import { routes } from "@/router";
import TSpacer from "@/components/TSpacer.vue";
import TButton from "@/components/TButton.vue";

@Component({
  components: {
    "t-button": TButton,
    "t-spacer": TSpacer,
    "t-row": TRow,
    "t-col": TCol
  }
})
export default class NavigationBar extends Vue {
  private get navigationEntries() {
    return routes.filter(it => it.meta.navigable);
  }
}
