







import Vue from "vue";
import Component from "vue-class-component";
import NavigationBar from "@/components/NavigationBar.vue";
import { Breakpoints } from "@/util/Breakpoints";

@Component({
  components: { NavigationBar }
})
export default class App extends Vue {
  // Needs to be here to be reactive...
  private breakpoints: Breakpoints = new Breakpoints();

  private created() {
    Vue.prototype.$breakpoints = this.breakpoints;
    Vue.prototype.$breakpoints.setup();
  }

  private destroy() {
    Vue.prototype.$breakpoints.teardown();
  }
}
