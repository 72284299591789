












































































































import Vue from "vue";
import Component from "vue-class-component";
import TContainer from "@/components/TContainer.vue";
import TRow from "@/components/TRow.vue";
import TCol from "@/components/TCol.vue";
import TButton from "@/components/TButton.vue";
import ResultPreview from "@/components/ResultPreview.vue";
import { ElementPosition } from "@/store/Types";
import Recorder from "@/util/Recorder";
import { streamDimensions } from "@/util/MediaUtil";
import { Prop, Watch } from "vue-property-decorator";
import { getSizeFull } from "@/util/MeasurementUtils";
import TDialog from "@/components/TDialog.vue";
import TCard from "@/components/TCard.vue";
import TTextField from "@/components/TTextField.vue";
import TSpacer from "@/components/TSpacer.vue";
import TForm from "@/components/TForm.vue";

@Component({
  components: {
    TForm,
    TSpacer,
    TTextField,
    TCard,
    TDialog,
    ResultPreview,
    TButton,
    TCol,
    TRow,
    TContainer
  }
})
export default class RecordingPane extends Vue {
  private webcamPosition: ElementPosition | null = null;
  private recorder: Recorder | null = null;
  private resultBlob: Blob | null = null;
  private containerWidth: number | null = null;
  private countdownDialogOpen = false;
  private countdownSecondsStr = "";
  private countdownValid = true;
  private currentCountdown: number | null = null;

  @Prop()
  private readonly webcamStream!: MediaStream | null;
  @Prop()
  private readonly audioTracks!: MediaStreamTrack[];
  @Prop()
  private readonly screenshare!: MediaStream | null;
  @Prop()
  private readonly streamToFile!: boolean;

  private get blobDownloadLink() {
    if (!this.resultBlob) {
      return null;
    }
    return URL.createObjectURL(this.resultBlob);
  }

  private get countdownSeconds() {
    if (this.countdownSecondsStr === "") {
      return 0;
    }
    return parseInt(this.countdownSecondsStr);
  }

  private get screenshareDimensions() {
    if (!this.containerWidth) {
      throw Error(":(");
    }

    let width: number;
    let height: number;

    if (this.screenshare) {
      width = streamDimensions(this.screenshare).width;
      height = streamDimensions(this.screenshare).height;
    } else if (this.webcamStream) {
      width = streamDimensions(this.webcamStream).width;
      height = streamDimensions(this.webcamStream).height;
    } else {
      return null;
    }

    const newWidth = this.containerWidth;
    const originalRatio = height / width;
    return {
      width: newWidth,
      height: newWidth * originalRatio
    };
  }

  @Watch("webcamPosition")
  private onWebcamPositionUpdate() {
    if (this.recorder && this.webcamPosition) {
      this.recorder.setWebcamLocation(this.webcamPosition);
    }
  }

  private toggleRecording() {
    if (this.recorder) {
      this.recorder.stopRecording();
      this.recorder = null;
    } else {
      if (this.countdownSeconds === 0) {
        this.startRecordingImmediately();
        return;
      }

      this.currentCountdown = this.countdownSeconds;

      const timer = setInterval(() => {
        if (this.currentCountdown) {
          this.currentCountdown -= 1;
        }
        if (this.currentCountdown === null || this.currentCountdown === 0) {
          clearInterval(timer);
          this.startRecordingImmediately();
          this.currentCountdown = null;
        }
      }, 1000);
    }
  }

  private startRecordingImmediately() {
    if (!this.canvasSize) {
      return;
    }
    this.resultBlob = null;
    this.recorder = new Recorder({
      webcamLocation: this.webcamPosition || {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      },
      screenSize: this.canvasSize,
      screenStream: this.screenshare || undefined,
      webcamStream: this.webcamStream || undefined,
      finishCallback: blob => (this.resultBlob = blob || null),
      streamToFile: this.streamToFile,
      audioTracks: this.audioTracks
    });
    this.recorder.startRecording();
  }

  private get canvasSize() {
    if (this.screenshare) {
      return streamDimensions(this.screenshare);
    } else if (this.webcamStream) {
      return streamDimensions(this.webcamStream);
    }
    return null;
  }

  private updateContainerSize() {
    if (!this.canvasSize) {
      return null;
    }
    const { width } = this.canvasSize;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const root = (this.$refs["root"] as any).$el as HTMLElement;
    this.containerWidth = Math.min(getSizeFull(root).width * 0.8, width);
  }

  private restart() {
    if (
      window.confirm("Do you really want to restart the capturing process?")
    ) {
      this.$emit("restart");
    }
  }

  private ruleIsPositiveNumber(input: string) {
    // Allow leaving it empty
    if (!input) {
      return null;
    }
    const number = parseInt(input);
    if (Number.isNaN(number)) {
      return "Must be a number";
    }
    return number >= 0 ? null : "Must be positive";
  }

  private mounted() {
    this.updateContainerSize();
  }

  private created() {
    window.addEventListener("resize", this.updateContainerSize);
  }
  private destroyed() {
    window.removeEventListener("resize", this.updateContainerSize);
  }
}
