var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"resizer",style:({
    width: this.resizerWidth,
    height: this.resizerHeight,
    'max-width':
      this.maxWidth !== null ? this.maxWidth + 2 + 'px' : undefined,
    'max-height':
      this.maxHeight !== null ? this.maxHeight + 2 + 'px' : undefined
  }),on:{"mousedown":_vm.containerMouseDown}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }