






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class TRow extends Vue {
  @Prop({ default: false, type: Boolean })
  private readonly noGutters!: boolean;

  @Prop({ default: false, type: Boolean })
  private readonly alignLeft!: boolean;

  @Prop({ default: false, type: Boolean })
  private readonly wrap!: boolean;

  private get computedClass() {
    return {
      "no-gutters": this.noGutters,
      "t-row": true,
      "align-left": this.alignLeft,
      wrap: this.wrap
    };
  }
}
