





























































































import Vue from "vue";
import Component from "vue-class-component";
import TContainer from "@/components/TContainer.vue";
import TRow from "@/components/TRow.vue";
import TCol from "@/components/TCol.vue";
import MediaStreamDisplay from "@/components/MediaStreamDisplay.vue";
import { getMicrophone, getScreen, getWebcam } from "@/util/MediaUtil";
import Resizer from "@/components/Resizer.vue";
import AbsolutePositioningPane from "@/components/AbsolutePositioningPane.vue";
import TButton from "@/components/TButton.vue";
import RecordingPane from "@/components/RecordingPane.vue";
import TCheckbox from "@/components/TCheckbox.vue";
import TForm from "@/components/TForm.vue";

type Error = {
  component: string;
  message: string;
};

@Component({
  components: {
    TForm,
    TCheckbox,
    RecordingPane,
    TButton,
    AbsolutePositioningPane,
    Resizer,
    MediaStreamDisplay,
    TCol,
    TRow,
    TContainer
  }
})
export default class Home extends Vue {
  private webcamStream: MediaStream | null = null;
  private screenshare: MediaStream | null = null;
  private audioTracks: MediaStreamTrack[] = [];
  private streamToFile = true;
  private useMicrophone = true;
  private useWebcam = true;
  private useScreenPref = true;
  private useComputerAudio = false;
  private errors: Error[] = [];
  private formValid = true;
  private ready = false;
  private waitingForPermissions = false;

  private get canShareScreen() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(navigator.mediaDevices as any).getDisplayMedia;
  }

  private get useScreen() {
    return this.useScreenPref && this.canShareScreen;
  }

  private sourceSelected(input: boolean) {
    if (this.useWebcam || this.useScreen) {
      return null;
    }
    if (input) {
      return null;
    }
    return "Webcam/Screenshare needed";
  }

  private lostTrackAccess() {
    this.errors = [
      {
        component: "Lost Access",
        message:
          "I lost access to your devices, did you revoke it? Operation can not" +
          " continue, please click 'Change Settings' when you are ready."
      }
    ];
  }

  private deinit() {
    if (this.webcamStream) {
      this.webcamStream.getTracks().forEach(it => it.stop());
    }
    this.webcamStream = null;

    if (this.screenshare) {
      this.screenshare.getTracks().forEach(it => it.stop());
    }
    this.screenshare = null;

    if (this.audioTracks.length !== 0) {
      this.audioTracks.forEach(it => it.stop());
    }
    this.audioTracks = [];

    this.errors = [];
    this.ready = false;
    this.waitingForPermissions = false;
  }

  private async init() {
    this.waitingForPermissions = true;
    this.errors = [];
    if (this.useWebcam) {
      await this.doWithError("Webcam", () =>
        getWebcam().then(it => (this.webcamStream = it))
      );
    }
    if (this.useMicrophone) {
      await this.doWithError("Microphone", () =>
        getMicrophone().then(micStream => {
          if (micStream) {
            this.audioTracks.push(micStream.getAudioTracks()[0]);
          }
        })
      );
    }
    if (this.useScreen) {
      await this.doWithError("Screenshare", () =>
        getScreen().then(it => (this.screenshare = it))
      );
      if (this.screenshare && this.useComputerAudio) {
        if (this.screenshare.getAudioTracks().length === 0) {
          this.errors.push({
            component: "Computer audio",
            message: "Sorry, not supported on your system (or you denied it)"
          });
        }
        this.audioTracks = this.audioTracks.concat(
          this.screenshare.getAudioTracks()
        );
      }
    }

    this.ready = true;
    this.waitingForPermissions = false;

    if (this.audioTracks) {
      this.audioTracks.forEach(track => (track.onended = this.lostTrackAccess));
    }
    if (this.webcamStream) {
      this.webcamStream
        .getTracks()
        .forEach(track => (track.onended = this.lostTrackAccess));
    }
    if (this.screenshare) {
      this.screenshare
        .getTracks()
        .forEach(track => (track.onended = this.lostTrackAccess));
    }
  }

  private async doWithError(component: string, func: () => Promise<unknown>) {
    try {
      await func();
    } catch (e) {
      this.errors.push({ component: component, message: e.message });
    }
  }
}
