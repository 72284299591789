






























import Component, { mixins } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";
import TRow from "@/components/TRow.vue";
import TCol from "@/components/TCol.vue";
import TContainer from "@/components/TContainer.vue";
import ValidatableMixin from "@/mixins/ValidatableMixin.vue";

@Component({
  components: {
    "t-container": TContainer,
    "t-row": TRow,
    "t-col": TCol
  }
})
export default class TTextField extends mixins(ValidatableMixin) {
  @Prop({ default: "" })
  private readonly placeholder!: string;

  @Model("change", { default: "" })
  private readonly value!: string;

  @Prop({ default: "" })
  private readonly label!: string;

  @Prop({ default: () => [] })
  private readonly rules!: ((input: string) => string | null)[];

  @Prop({ default: false, type: Boolean })
  private readonly errorHighlight!: boolean;

  @Prop({ default: null })
  private readonly chars!: number | null;

  @Watch("value")
  private updateValidator() {
    this.validator.validate();
  }

  validate(): boolean {
    return this.validationResult === null;
  }

  private get validationResult(): string | null {
    for (const rule of this.rules) {
      const result = rule(this.value);
      if (result) {
        return result;
      }
    }

    return null;
  }

  private get computedInputStyle() {
    return {
      width: this.chars ? `${this.chars + 2}ch` : undefined,
      "max-width": this.chars ? `${this.chars + 2}ch` : undefined
    };
  }
}
