






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class TContainer extends Vue {
  @Prop({ default: false, type: Boolean })
  private readonly fluid!: boolean;

  @Prop({ default: false, type: Boolean })
  private readonly noGutter!: boolean;

  @Prop({ default: false, type: Boolean })
  private readonly wrap!: boolean;

  private get computedClass() {
    return {
      fluid: this.fluid,
      "t-container": true,
      "no-gutters": this.noGutter,
      wrap: this.wrap
    };
  }
}
