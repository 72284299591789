














import Vue from "vue";
import Component from "vue-class-component";
import TContainer from "./TContainer.vue";
import TRow from "@/components/TRow.vue";
import TCol from "@/components/TCol.vue";

@Component({
  components: {
    "t-col": TCol,
    "t-row": TRow,
    "t-container": TContainer
  }
})
export default class TCard extends Vue {
  private get hasTitleSlot() {
    return !!this.$slots.title;
  }
  private get hasActionsSlot() {
    return !!this.$slots.actions;
  }
}
